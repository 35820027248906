import { gql } from "@apollo/client"

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmailInput($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      user {
        username
      }
    }
  }
`

export default SEND_PASSWORD_RESET_EMAIL
