import React, { useState } from "react"
import { isEmpty } from "lodash"
import { useMutation } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import Input from "components/Input/Input"
import Button from "components/Button/Button"

import SEND_PASSWORD_RESET_EMAIL from "mutations/send-password-reset-email"

const PasswordResetEmail = () => {
  const [userName, setUserName] = useState("")

  const [sendReset, { loading: sendResetLoading, error: sendResetError }] =
    useMutation(SEND_PASSWORD_RESET_EMAIL, {
      variables: {
        input: {
          clientMutationId: v4(),
          username: userName,
        },
      },
      onCompleted: data => {
        if (!isEmpty(sendResetError)) {
          toast.error("Wystąpił błąd")
          console.warn(sendResetError.graphQLErrors[0].message)
        }
        toast.success("Wysłano link!")
        setUserName("")
      },
      onError: error => {
        if (error) {
          if (!isEmpty(error)) {
            toast.error("Wystąpił błąd")
            console.warn(error.graphQLErrors[0].message)
          }
        }
      },
    })

  const onFormSubmit = e => {
    e.preventDefault()
    sendReset()
  }

  return (
    <form className="form" onSubmit={onFormSubmit}>
      <Input
        id="text"
        type="username"
        placeholder="Podaj nazwę użytkownika"
        value={userName}
        onChange={e => setUserName(e.target.value)}
      />
      <div className="form__button">
        <Button type="submit">
          {sendResetLoading ? "Resetowanie..." : "Zresetuj"}
        </Button>
      </div>
    </form>
  )
}

export default PasswordResetEmail
