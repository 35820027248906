import "styles/pages/login.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import PasswordResetEmail from "components/PasswordResetEmail/PasswordResetEmail"

const ResetPass = () => {
  const title = "Zresetuj hasło"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="login">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row justify-content-center">
            <div className="col-md-4">
              <PageSubHeader title={title} />

              <PasswordResetEmail />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ResetPass
